<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue'

const props = defineProps<{
  checked: boolean | Array<string | number>
  value?: string | number
}>()

const emit = defineEmits<{
  (e: 'update:checked', value: boolean | Array<string | number>): void
  (e: 'change', value: boolean | Array<string | number>): void
}>()

const isChecked = computed({
  get() {
    return Array.isArray(props.checked) ? props.checked.includes(props.value!) : props.checked
  },
  set(val) {
    if (Array.isArray(props.checked)) {
      const newValue = val ? [...props.checked, props.value!] : props.checked.filter((item) => item !== props.value)
      emit('update:checked', newValue)
    } else {
      emit('update:checked', val)
    }
    emit('change', val)
  },
})
</script>

<template>
  <input
    v-model="isChecked"
    type="checkbox"
    class="h-4 w-4 cursor-pointer rounded border-gray-300 text-orange-600 focus:ring-orange-600"
  />
</template>
